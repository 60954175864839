"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.json.stringify.js");

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: ["data", "capital_name", "exchangeLimit"],
  computed: {
    confirmNum: function confirmNum() {
      if (JSON.stringify(this.data) !== "{}") {
        var confirm_num = this.data.itemDetail.confirm_num;
        return confirm_num;
      } else {
        return "--";
      }
    }
  }
};
exports.default = _default;