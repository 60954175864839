"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiBuyFinances = apiBuyFinances;
exports.apiGetDeFiProductList = apiGetDeFiProductList;
exports.apiGetFinancesProductInfo = apiGetFinancesProductInfo;
exports.apiGetHistoryPositionList = apiGetHistoryPositionList;
exports.apiGetPositionInfo = apiGetPositionInfo;
exports.apiGetProductInfo = apiGetProductInfo;
exports.apiGetProductListV2 = apiGetProductListV2;
exports.apiSellFinances = apiSellFinances;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
//理财产品列表
function apiGetProductListV2() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/Publics/getFinanceList",
    data: data,
    type: "POST"
  });
} //获取理财产品详情


function apiGetFinancesProductInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Finances/getProductInfo",
    data: data,
    type: "POST"
  });
} //获取理财产品详情 - 无登录认证


function apiGetProductInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Product/getProductInfo",
    data: data,
    type: "POST"
  });
} // 购买理财产品


function apiBuyFinances() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Finances/buy",
    data: data,
    type: "POST"
  });
} // 赎回理财产品


function apiSellFinances() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Finances/sell",
    data: data,
    type: "POST"
  });
} // 用户持仓详情


function apiGetPositionInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Finances/getPositionInfo",
    data: data,
    type: "POST"
  });
} // DeFi产品列表页


function apiGetDeFiProductList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/DeFi/getProductList",
    data: data,
    type: "POST"
  });
} // 理财产品持仓历史列表


function apiGetHistoryPositionList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/Finances/historyPositionList",
    data: data,
    type: "POST"
  });
}