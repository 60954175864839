"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAllClosePosition = apiAllClosePosition;
exports.apiAppendInterest = apiAppendInterest;
exports.apiCancelOrde = apiCancelOrde;
exports.apiClosePosition = apiClosePosition;
exports.apiGetContract = apiGetContract;
exports.apiGetOpenPositionList = apiGetOpenPositionList;
exports.apiGetOrderList = apiGetOrderList;
exports.apiGetOrderStatistic = apiGetOrderStatistic;
exports.apiGetPlanOrderList = apiGetPlanOrderList;
exports.apiOpenPosition = apiOpenPosition;
exports.apiPlanOrder = apiPlanOrder;
exports.apiRevokeAllPlan = apiRevokeAllPlan;
exports.apiRevokePlan = apiRevokePlan;
exports.apiSetPlanProfitLoss = apiSetPlanProfitLoss;
exports.apiSetPositionProfitLoss = apiSetPositionProfitLoss;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
function apiGetOrderList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/getOrderListV2",
    data: data,
    type: "POST"
  });
}

function apiCancelOrde() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/cancelLimitOrder",
    data: data,
    type: "POST"
  });
} // 持仓  平仓记录


function apiGetOpenPositionList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/openPositionList",
    data: data,
    type: "POST"
  });
} // 当前委托  历史委托


function apiGetPlanOrderList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/planOrderList",
    data: data,
    type: "POST"
  });
} // apiRevokePlan
// 合约撤销计划委托


function apiRevokePlan() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/revokePlan",
    data: data,
    type: "POST"
  });
} //
// 合约追加持仓


function apiAppendInterest() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/appendInterest",
    data: data,
    type: "POST"
  });
} // 市价合约下单


function apiOpenPosition() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/openPosition",
    data: data,
    type: "POST"
  });
} // 限价合约下单


function apiPlanOrder() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/planOrder",
    data: data,
    type: "POST"
  });
} // 合约平仓


function apiClosePosition() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/closePosition",
    data: data,
    type: "POST"
  });
} // 合约一键平仓


function apiAllClosePosition() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/allClosePosition",
    data: data,
    type: "POST"
  });
} // 合约仓位止盈止损


function apiSetPositionProfitLoss() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/setPositionProfitLoss",
    data: data,
    type: "POST"
  });
} // 合约委托单止盈止损


function apiSetPlanProfitLoss() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/setPlanProfitLoss",
    data: data,
    type: "POST"
  });
} // 撤销所有计划委托


function apiRevokeAllPlan() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/revokeAllPlan",
    data: data,
    type: "POST"
  });
} // 用户合约统计数据


function apiGetOrderStatistic() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Contract/statistic",
    data: data,
    type: "POST"
  });
} //币本位合约接口整合 undingRate,planOrderList,openPositionList   当前委托  历史委托   持仓  平仓记录


function apiGetContract() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Merge/contract",
    data: data,
    type: "POST"
  });
}