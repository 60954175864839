"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _user = _interopRequireDefault(require("./modules/user"));

_vue.default.use(_vuex.default);

var store = new _vuex.default.Store({
  modules: {
    user: _user.default
  }
});
var _default = store;
exports.default = _default;