"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _store = _interopRequireDefault(require("@/store"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

// 未登录可以访问的页面
var whiteList = ["/", "/login", "/register", "/login/mobile", "/loginSecurity"];
var notLoginList = ["/login", "/ucenter/assets", "/ucenter/security-setting", "/ucenter/situation", "/ucenter/finanManagement", "/ucenter/spread-center", "/ucenter/coupon-center", "/ucenter/safe-setting"];

function _default(router) {
  router.beforeEach(function (to, from, next) {
    // 白名单不做登录验证
    if (whiteList.some(function (path) {
      return to.path.includes(path);
    })) {
      next();
    } else {
      // 如果未登录跳转登录页面
      // const token = window.$.cookie('topcredit_exchange_t')
      // if (!token) {
      //   router.push('/login');
      //   next();
      // }
      // 校验是否有token
      var temmToken = _jsCookie.default.get("topcredit_exchange_t");

      if (temmToken) {
        _store.default.dispatch("user/getUserInfo");
      } else {
        if (notLoginList.some(function (path) {
          return to.path.includes(path);
        })) {
          next({
            path: "/login"
          });
        } else {
          next();
        }
      }

      next();
    }
  });
}