"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileUpload = fileUpload;
exports.videoUpload = videoUpload;

require("core-js/modules/es.object.to-string.js");

var _axios = _interopRequireDefault(require("axios"));

var _api = _interopRequireDefault(require("@/assets/js/api"));

// import { Message } from 'element-ui'
//图片上传
function fileUpload(url, params, error) {
  var config = {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  }; //添加请求头

  return new Promise(function (resolve) {
    _axios.default.post(url, params, config).then(function (res) {
      resolve(res);
    }, function (err) {
      err = error ? error : err;
    });
  });
}

function videoUpload(url, params, error) {
  var config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }; //添加请求头

  return new Promise(function (resolve) {
    _api.default.post(url, params, config).then(function (res) {
      resolve(res);
    }, function (err) {
      err = error ? error : err;
    });
  });
}