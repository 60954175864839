"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiCancelOrde = apiCancelOrde;
exports.apiGetOrderList = apiGetOrderList;
exports.apiTrustCancel = apiTrustCancel;
exports.apiTrustDealIndex = apiTrustDealIndex;
exports.apiTrustIndex = apiTrustIndex;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
function apiGetOrderList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/getOrderListV2",
    data: data,
    type: "POST"
  });
}

function apiCancelOrde() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/cancelLimitOrder",
    data: data,
    type: "POST"
  });
} // 撤销订单新接口


function apiTrustCancel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Spot/Trade/trustCancel",
    data: data,
    type: "POST"
  });
} //用户委托列表


function apiTrustIndex() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Spot/Trade/trustIndex",
    data: data,
    type: "POST"
  });
} // 用户成交列表


function apiTrustDealIndex() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Spot/Trade/trustDealIndex",
    data: data,
    type: "POST"
  });
}