"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetLoginOut = apiGetLoginOut;
exports.apiGetUserInfo = apiGetUserInfo;
exports.getUserInfo = getUserInfo;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _stationSetting = require("@/assets/js/stationSetting");

function apiGetUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Users/getUserInfo"),
    data: data,
    type: "POST"
  });
}

function getUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!_jsCookie.default.get("topcredit_exchange_t")) return {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Users/getUserInfo"),
    data: data,
    type: "POST"
  });
} //登出


function apiGetLoginOut() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/User/loginOut"),
    data: data,
    type: "POST"
  });
}