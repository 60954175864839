"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.number.to-fixed.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

var _vuex = require("vuex");

var _vueEcharts = _interopRequireDefault(require("vue-echarts"));

var echarts = _interopRequireWildcard(require("echarts"));

var _formatDate = require("@/assets/js/formatDate");

var _i18n = require("@/assets/js/i18n");

var _keymap = require("@/assets/js/keymap.js");

var _message = require("@/components/dialog/message");

var _vue2Countdown = _interopRequireDefault(require("@choujiaojiao/vue2-countdown"));

var _finance = require("@/model/finance/finance");

var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  dialogVisible: true,
  components: {
    Countdown: _vue2Countdown.default,
    "v-chart": _vueEcharts.default
  },
  props: {
    productId: {
      type: [String, Number],
      default: ""
    }
  },
  data: function data() {
    var _this = this;

    return {
      showName: "",
      showData: "",
      rateTypeMaps: _keymap.rateTypeMaps,
      quantTagMaps: _keymap.quantTagMaps,
      financialStatusMaps: _keymap.financialStatusMaps,
      pageLoading: true,
      stepActive: 1,
      rate: 1,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      tabList: [{
        value: "1",
        label: "7日年化"
      }, {
        value: "2",
        label: "30日年化"
      }, {
        value: "3",
        label: "历史净值"
      }],
      pieData: [{
        value: 70,
        name: "项目运作人"
      }, {
        value: 30,
        name: "份额持有人"
      }],
      options: {
        color: ["#3a7dff", "#f04a5a"],
        tooltip: {
          show: false
        },
        series: [{
          name: "利润分配",
          type: "pie",
          radius: ["30%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "12"
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 30,
            name: "份额持有人"
          }, {
            value: 70,
            name: "项目运作人"
          }]
        }]
      },
      mutiKlineOption: {
        color: ["#f04a5a"],
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function formatter(val) {
            _this.showName = val[0].name;
            _this.showData = val[0].data * 100;
            var result = "\n          ".concat(val[0].name, "<br />\n          ").concat(val[0].data * 100, "%\n        ");
            return result;
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "20%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          data: []
        }],
        yAxis: [{
          type: "value",
          axisLabel: {
            formatter: function formatter(val) {
              return (val * 100).toFixed(0) + "%";
            }
          }
        }],
        series: [{
          name: "",
          type: "line",
          stack: "总量",
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "rgba(255, 74, 120,1)"
            }, {
              offset: 1,
              color: "rgba(255, 134, 165, 0.09)"
            }])
          },
          fillOpacity: 0.1,
          emphasis: {
            focus: "series"
          },
          data: []
        }]
      },
      incRateList: [],
      rateList: [],
      rateList30: [],
      klineXList: [],
      klineYList: [],
      currentKlineOption: {
        color: ["#f04a5a"],
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function formatter(val) {
            _this.showName = val[0].name;
            _this.showData = (val[0].data * 100).toFixed(2);
            var result = "\n          ".concat(val[0].name, "<br />\n          ").concat((val[0].data * 100).toFixed(2), "%\n        ");
            return result;
          }
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          top: "10%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          data: this.klineXList
        }],
        yAxis: [{
          type: "value",
          axisLabel: {
            formatter: function formatter(val) {
              return (val * 100).toFixed(2) + "%";
            }
          }
        }],
        series: [{
          name: "",
          type: "line",
          stack: "总量",
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "rgba(255, 74, 120,1)"
            }, {
              offset: 1,
              color: "rgba(255, 134, 165, 0.09)"
            }])
          },
          fillOpacity: 0.1,
          emphasis: {
            focus: "series"
          },
          data: this.klineYList
        }]
      },
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 3,
        rate_type: 0,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "0.0000",
        max_fluctuation_annual_rate: "0.0000",
        day_rate: 0,
        current_rate: "0.0000",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: 7,
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url: "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang: "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33"
        },
        item_name: "USDT",
        item_url: require("../../../../assets/img/holder-coin-ico.png"),
        to_item_url: "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "O3",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0
      }
    };
  },
  created: function created() {
    this.getFinancesProductInfo();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    userInfo: "user/userInfo",
    language: "user/language"
  })),
  methods: {
    routeSplicing: _routeSplicing.default,
    timestampToDate: _formatDate.timestampToDate,
    timestampToDay: _formatDate.timestampToDay,
    translateTitle: _i18n.translateTitle,
    onCountDownEnd: function onCountDownEnd() {
      this.onDialogClose();
    },
    onOK: function onOK() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
        return;
      }

      this.$emit("onConfirm");
    },
    onTabClick: function onTabClick(item) {
      var _this2 = this;

      this.currTab = item.value;
      this.klineXList = [];
      this.klineYList = [];

      if (item.value == 1) {
        this.rateList.forEach(function (ele) {
          _this2.klineXList.push(_this2.timestampToDay(ele.date));

          _this2.klineYList.push(ele.rate);
        });
      }

      if (item.value == 2) {
        this.rateList30.forEach(function (ele) {
          _this2.klineXList.push(_this2.timestampToDay(ele.date));

          _this2.klineYList.push(ele.rate);
        });
      } else if (item.value == 3) {
        this.incRateList.forEach(function (ele) {
          _this2.klineXList.push(_this2.timestampToDay(ele.date));

          _this2.klineYList.push(ele.rate);
        });
      }

      this.mutiKlineOption.xAxis[0].data = this.klineXList;
      this.mutiKlineOption.series[0].data = this.klineYList;
      this.$nextTick(function () {
        _this2.$refs.mutiKlineOption.setOption(_this2.mutiKlineOption);
      });
    },
    onDialogClose: function onDialogClose() {
      this.$emit("onDialogClose");
    },
    getFinancesProductInfo: function getFinancesProductInfo() {
      var _this3 = this;

      var params = {
        product_id: this.productId
      };
      this.pageLoading = true;
      (0, _finance.apiGetProductInfo)(params).then(function (res) {
        if (res.status == 200) {
          _this3.productInfo = res.data.productInfo;

          _this3.$emit("onProductInfoChange", _this3.productInfo);

          _this3.xList = res.data.rateList;
          _this3.rateList = res.data.rateList;
          _this3.rateList30 = res.data.rateList30;
          _this3.incRateList = res.data.incRateList;
          _this3.klineXList = [];
          _this3.klineYList = [];

          _this3.rateList.forEach(function (ele) {
            _this3.klineXList.push(_this3.timestampToDay(ele.date));

            _this3.klineYList.push(ele.rate);
          });

          var now_time = (new Date().getTime() / 1000).toFixed();
          _this3.rate = (_this3.productInfo.total_amount / _this3.productInfo.amount * 100).toFixed(2);
          _this3.rate1 = (now_time - _this3.productInfo.start_time) / (_this3.productInfo.stop_time - _this3.productInfo.start_time);
          _this3.rate1 = _this3.rate1 > 1 ? 1 : _this3.rate1;
          _this3.rate2 = (now_time - _this3.productInfo.stop_time) / (_this3.productInfo.end_time - _this3.productInfo.stop_time);
          _this3.rate2 = _this3.rate2 > 1 ? 1 : _this3.rate2;

          if (_this3.productInfo.status == 3) {
            _this3.stepActive = 1;
          } else if (_this3.productInfo.status >= 4 && _this3.productInfo.status < 7) {
            _this3.stepActive = 2;
          } else if (_this3.productInfo.status == 7) {
            _this3.stepActive = 3;
          }

          if (_this3.productInfo.product_type != 2) {
            var now = Date.parse(new Date());

            if (_this3.productInfo.start_time != 0) {
              _this3.productInfo.remain_time = _this3.productInfo.start_time * 1000 - now;
            } else {
              _this3.productInfo.timeCount = 0;
            }
          }

          if (_this3.productInfo.product_type == 2) {
            _this3.currentKlineOption.xAxis[0].data = _this3.klineXList;
            _this3.currentKlineOption.series[0].data = _this3.klineYList;
            _this3.mutiKlineOption.xAxis[0].data = _this3.klineXList;
            _this3.mutiKlineOption.series[0].data = _this3.klineYList;
            _this3.showName = (0, _formatDate.timestampToDay)(_this3.xList[_this3.xList.length - 1].date);
            _this3.showData = _this3.productInfo.annual_rate;
          } else if (_this3.productInfo.product_type == 3) {
            _this3.mutiKlineOption.xAxis[0].data = _this3.klineXList;
            _this3.mutiKlineOption.series[0].data = _this3.klineYList;
          }

          _this3.options.series[0].data[0].value = _this3.productInfo.user_profit;
          _this3.options.series[0].data[1].value = _this3.productInfo.trader_profit;
        } else {
          (0, _message.showMessage)({
            message: res.msg,
            type: "error"
          });
        }

        _this3.pageLoading = false;

        _this3.$nextTick(function () {
          if (_this3.productInfo.product_type == 2) {
            _this3.$refs.currentKlineOption.setOption(_this3.currentKlineOption);

            _this3.$refs.mutiKlineOption.setOption(_this3.mutiKlineOption);
          } else if (_this3.productInfo.product_type == 3) {
            _this3.$refs.mutiKlineOption.setOption(_this3.mutiKlineOption);

            _this3.$refs.dataEchart.setOption(_this3.options);
          }
        });
      });
    }
  }
};
exports.default = _default;