"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetBanner = apiGetBanner;
exports.apiGetBlogDetails = apiGetBlogDetails;
exports.apiGetBlogList = apiGetBlogList;
exports.apiGetHotList = apiGetHotList;
exports.apiGetTags = apiGetTags;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// Blog 列表
function apiGetBlogList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Blog/Common/blogList",
    data: data,
    type: "POST"
  });
} // Blog 右侧 列表


function apiGetHotList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Blog/Common/hotList",
    data: data,
    type: "POST"
  });
} // Blog banner 列表


function apiGetBanner() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Blog/Common/banner",
    data: data,
    type: "POST"
  });
} // Blog banner 详情


function apiGetBlogDetails() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Blog/Common/details",
    data: data,
    type: "POST"
  });
} // Blog  TAG 列表


function apiGetTags() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Blog/Common/tags",
    data: data,
    type: "POST"
  });
}