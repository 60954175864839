"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = formatTime;
exports.on = exports.off = void 0;
exports.paramObj = paramObj;
exports.parseTime = parseTime;
exports.random = random;
exports.tenBitTimestamp = tenBitTimestamp;
exports.thirteenBitTimestamp = thirteenBitTimestamp;
exports.timestampToDate = timestampToDate;
exports.timestampToDay = timestampToDay;
exports.timestampToYear = timestampToYear;
exports.translateDataToTree = translateDataToTree;
exports.translateTreeToData = translateTreeToData;
exports.uuid = uuid;

var _typeof2 = _interopRequireDefault(require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/esm/typeof.js"));

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.test.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.function.name.js");

/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
function timestampToDay(created) {
  var stamp = "".concat(created, "000");
  var date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM-dd");
}

function timestampToDate(created) {
  var stamp = "".concat(created, "000");
  var date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM.dd HH:mm");
}

function timestampToYear(created) {
  var stamp = "".concat(created, "000");
  var date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "yyyy-MM-dd HH:mm");
}

function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  var date;

  if ((0, _typeof2.default)(time) === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  return format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];

    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }

    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }

    return value || 0;
  });
}
/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */


function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}
/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */


function paramObj(url) {
  var search = url.split("?")[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}
/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */


function translateDataToTree(data) {
  var parent = data.filter(function (value) {
    return value.parentId === "undefined" || value.parentId == null;
  });
  var children = data.filter(function (value) {
    return value.parentId !== "undefined" && value.parentId != null;
  });

  var translator = function translator(parent, children) {
    parent.forEach(function (parent) {
      children.forEach(function (current, index) {
        if (current.parentId === parent.id) {
          var temp = JSON.parse(JSON.stringify(children));
          temp.splice(index, 1);
          translator([current], temp);
          typeof parent.children !== "undefined" ? parent.children.push(current) : parent.children = [current];
        }
      });
    });
  };

  translator(parent, children);
  return parent;
}
/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */


function translateTreeToData(data) {
  var result = [];
  data.forEach(function (item) {
    var loop = function loop(data) {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId
      });
      var child = data.children;

      if (child) {
        for (var i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };

    loop(item);
  });
  return result;
}
/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */


function tenBitTimestamp(time) {
  var date = new Date(time * 1000);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  var d = date.getDate();
  d = d < 10 ? "" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}
/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */


function thirteenBitTimestamp(time) {
  var date = new Date(time / 1);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  var d = date.getDate();
  d = d < 10 ? "" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}
/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */


function uuid() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 32;
  var num = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  var str = "";

  for (var i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length));
  }

  return str;
}
/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */


function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n);
}
/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */


var on = function () {
  return function (element, event, handler) {
    var useCapture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture);
    }
  };
}();
/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */


exports.on = on;

var off = function () {
  return function (element, event, handler) {
    var useCapture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    if (element && event) {
      element.removeEventListener(event, handler, useCapture);
    }
  };
}();

exports.off = off;