"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindEmail = bindEmail;
exports.bindGoogleVerify = bindGoogleVerify;
exports.bindMobile = bindMobile;
exports.bingGoogleVerifyStatus = bingGoogleVerifyStatus;
exports.closeGoogleVerify = closeGoogleVerify;
exports.getGoogleSecret = getGoogleSecret;
exports.loginSendEmailCode = loginSendEmailCode;
exports.loginSendSmsCode = loginSendSmsCode;
exports.openGoogleVerify = openGoogleVerify;
exports.sendEmailCode = sendEmailCode;
exports.sendSignEmailCode = sendSignEmailCode;
exports.sendSignSmsCode = sendSignSmsCode;
exports.sendSmsCode = sendSmsCode;
exports.undataMobile = undataMobile;
exports.updateGoogleVerify = updateGoogleVerify;
exports.updatePassword = updatePassword;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER } = process.env;
// //获取手机验证码 (非登录态)
// export function sendSignSmsCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignSmsCode`,
//     data,
//     type: "POST",
//   });
// }
//获取手机验证码 (非登录态)
function sendSignSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/Forget/sms",
    data: data,
    type: "POST"
  });
} // 获取手机验证码（二次校验）


function loginSendSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/LoginVerify/sendSmsCode",
    data: data,
    type: "POST"
  });
} // 获取手机验证码（完全登录态）


function sendSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/sendSmsCode",
    data: data,
    type: "POST"
  });
} // // 获取邮箱验证码（非登录态）
// export function sendSignEmailCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignEmailCode`,
//     data,
//     type: "POST",
//   });
// }
// 获取邮箱验证码（非登录态）


function sendSignEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/Forget/email",
    data: data,
    type: "POST"
  });
} // 获取邮箱验证码（二次校验）


function loginSendEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/LoginVerify/sendEmailCode",
    data: data,
    type: "POST"
  });
} // 获取邮箱验证码(完全登录态)


function sendEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Users/sendEmailCode",
    data: data,
    type: "POST"
  });
} // // 修改密码
// export function updatePassword(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updatePassword`,
//     data,
//     type: "POST",
//   });
// }
// 修改密码


function updatePassword() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/updatePassword",
    data: data,
    type: "POST"
  });
} // // 绑定手机
// export function bindMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindMobile`,
//     data,
//     type: "POST",
//   });
// }
// 绑定手机


function bindMobile() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/bindMobile",
    data: data,
    type: "POST"
  });
} // // 绑定邮箱
// export function bindEmail(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindEmail`,
//     data,
//     type: "POST",
//   });
// }
// 绑定邮箱


function bindEmail() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/bindEmail",
    data: data,
    type: "POST"
  });
} // // 修改绑定的手机
// export function undataMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateMobile`,
//     data,
//     type: "POST",
//   });
// }
// 修改绑定的手机


function undataMobile() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/changeMobile",
    data: data,
    type: "POST"
  });
} // // 获取谷歌令牌
// export function getGoogleSecret(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/getGoogleSecret`,
//     data,
//     type: "POST",
//   });
// }
// 获取谷歌令牌


function getGoogleSecret() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/bindGoogleCode",
    data: data,
    type: "POST"
  });
} // // 绑定谷歌令牌
// export function bindGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 绑定谷歌令牌


function bindGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/bindGoogle",
    data: data,
    type: "POST"
  });
} // // 修改谷歌令牌
// export function updateGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 修改谷歌令牌


function updateGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/changeGoogle",
    data: data,
    type: "POST"
  });
} // // 关闭谷歌认证
// export function closeGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/closeGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 关闭谷歌认证


function closeGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/closeGoogle",
    data: data,
    type: "POST"
  });
} // // 开启谷歌认证
// export function openGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/openGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 开启谷歌认证


function openGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/V3/UCenter/User/openGoogle",
    data: data,
    type: "POST"
  });
} // 获取谷歌绑定状态


function bingGoogleVerifyStatus() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/UCenter/Security/bingGoogleVerifyStatus",
    data: data,
    type: "POST"
  });
}