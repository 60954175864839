"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetDefaultToken = apiGetDefaultToken;
exports.apiLimitOrder = apiLimitOrder;
exports.apiSwitchCoin = apiSwitchCoin;
exports.apiWsUrl = apiWsUrl;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
function apiGetDefaultToken() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Publics/getDefaultToken",
    data: data,
    type: "POST"
  });
}

function apiWsUrl() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Publics/getWebInitInfo",
    data: data,
    type: "POST"
  });
} // 币币交易


function apiSwitchCoin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/switchCoin",
    data: data,
    type: "POST"
  });
} // 限价委托 （触发单）


function apiLimitOrder() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + "/App/TradeCoin/limitOrder",
    data: data,
    type: "POST"
  });
}