"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLangPackage = getLangPackage;

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _stationSetting = require("@/assets/js/stationSetting");

var _getSign = _interopRequireDefault(require("./getSign"));

var LANG_WEB_VERSION = "";
var LANG_WEB_PACKAGE = "";
var app_id = _stationSetting.VUE_APP_ID;
var option = {
  data: {}
};

if (!app_id) {
  app_id = localStorage.getItem("web-id");
}

function apiGetWebLang() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var tempLang = localStorage.getItem("language");
  option.data = request; // option.data.lang = tempLang || "zh_CN";

  option.data.app_id = app_id;
  option.data.__platform = 3;
  var token = window.$.cookie("topcredit_exchange_t") || sessionStorage.getItem("topcredit_exchange_t");
  var req = (0, _objectSpread2.default)({
    token: token
  }, option.data);
  req._CDCODE = (0, _getSign.default)(req, app_id);
  var result = "";
  var headers = {};
  headers["content-type"] = "application/x-www-form-urlencoded"; // headers["accept-encoding"] = "gzip,deflate";

  var arr = [];

  for (var field in req) {
    arr.push(field + "=" + req[field]);
  }

  result = arr.join("&");
  var data = {};
  var status = 0;
  window.$.ajax({
    dataType: "text",
    type: "POST",
    url: _stationSetting.VUE_APP_APP + url,
    //`/Lang/WebVersion`,
    data: result || "",
    headers: headers,
    processData: false,
    contentType: false,
    async: false,
    success: function success(result) {
      //请求成功
      result = JSON.parse(result);
      data = result.data;
      status = result.status;
    },
    error: function error(e) {//请求失败，包含具体的错误信息
    }
  });
  return {
    status: status,
    data: data
  };
}

function compare(array1, array2) {
  if (!array1 || !array2 || array1.length != array2.length) {
    return false;
  }

  array1 = array1.slice();
  array1.sort();
  array2 = array2.slice();
  array2.sort();

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] != array2[i]) {
      return false;
    }
  }

  return true;
}

function searchLangPackageChange() {
  var keyWordArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ["zh_CN"];
  var resLangVersion = {};
  var langWebVersionTimestamp = JSON.parse(localStorage.getItem("LangWebVersionTimestamp"));
  var unanimous = compare(keyWordArray, langWebVersionTimestamp && langWebVersionTimestamp.keyWordArray);

  if (langWebVersionTimestamp && parseInt(langWebVersionTimestamp.timestamp) + 3 * 60 * 1000 > new Date().valueOf() && unanimous) {
    return "";
  }

  resLangVersion = apiGetWebLang("/Lang/WebVersion");

  if (resLangVersion.status == 200) {
    localStorage.setItem("LangWebVersionTimestamp", JSON.stringify({
      timestamp: "".concat(new Date().valueOf()),
      keyWordArray: keyWordArray
    }));
    var varLangWebVersion = JSON.parse(localStorage.getItem("LangWebVersion"));

    if (!varLangWebVersion) {
      localStorage.setItem("LangWebVersion", JSON.stringify(resLangVersion.data));
      return keyWordArray;
    } else {
      if (JSON.stringify(varLangWebVersion) != JSON.stringify(resLangVersion.data)) {
        var tempWordArray = [];
        keyWordArray.forEach(function (keyWord) {
          if (!varLangWebVersion["".concat(keyWord)].version || varLangWebVersion["".concat(keyWord)].version != resLangVersion.data["".concat(keyWord)].version) {
            tempWordArray.push(keyWord);
            varLangWebVersion["".concat(keyWord)] = resLangVersion.data["".concat(keyWord)];
          }
        });
        localStorage.setItem("LangWebVersion", JSON.stringify(varLangWebVersion)); // if (
        //   !varLangWebVersion[`${keyWord}`].version ||
        //   varLangWebVersion[`${keyWord}`].version !=
        //     resLangVersion.data[`${keyWord}`].version
        // ) {
        //   tempWord = keyWord;
        // }
        //   localStorage.setItem(
        //     "LangWebVersion",
        //     JSON.stringify(resLangVersion.data)
        //   );

        return tempWordArray;
      } else {
        return "";
      }
    }
  }

  return "";
}

function getLangPackage() {
  var keyWordArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ["zh_CN"];
  var varLangWebVersion = JSON.parse(localStorage.getItem("LangWebVersion"));

  if (searchLangPackageChange(keyWordArray)) {
    var resLangPackage = apiGetWebLang("/Lang/WebLangPackage", {
      lang: keyWordArray.join(","),
      lang_version: varLangWebVersion ? varLangWebVersion["".concat(keyWordArray[0])] ? varLangWebVersion["".concat(keyWordArray[0])].version : 0 : 0
    });
    Object.keys(resLangPackage.data).forEach(function (keyWord) {
      var obj = {};
      obj[keyWord] = resLangPackage.data[keyWord];
      localStorage.setItem("lang_".concat(keyWord), JSON.stringify(obj));
    }); // localStorage.setItem(
    //   `lang_${keyWord}`,
    //   JSON.stringify(resLangPackage.data)
    // );
  } else {
    var empytLangKeyWord = [];
    keyWordArray.forEach(function (keyWord) {
      var obj = localStorage.getItem("lang_".concat(keyWord));
      if (!obj) empytLangKeyWord.push(keyWord);
    });

    if (empytLangKeyWord.length > 0) {
      var _resLangPackage = apiGetWebLang("/Lang/WebLangPackage", {
        lang: empytLangKeyWord.join(","),
        lang_version: varLangWebVersion ? varLangWebVersion["".concat(empytLangKeyWord[0])] ? varLangWebVersion["".concat(empytLangKeyWord[0])].version : 0 : 0
      });

      Object.keys(_resLangPackage.data).forEach(function (keyWord) {
        var obj = {};
        obj[keyWord] = _resLangPackage.data[keyWord];
        localStorage.setItem("lang_".concat(keyWord), JSON.stringify(obj));
      }); // localStorage.setItem(
      //   `lang_${keyWord}`,
      //   JSON.stringify(resLangPackage.data)
      // );
    }
  }

  var resultObj = {};
  keyWordArray.forEach(function (keyWord) {
    resultObj[keyWord] = JSON.parse(localStorage.getItem("lang_".concat(keyWord)))["".concat(keyWord)];
  });
  return resultObj;
}