"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.number.constructor.js");

var _getTime = require("@/assets/js/getTime");

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  mixins: [_commonMixin.default],
  props: {
    orderbookList: {
      type: Array,
      default: function _default() {
        return [{
          T: "--",
          p: "--",
          q: "--"
        }, {
          T: "--",
          p: "--",
          q: "--"
        }, {
          T: "--",
          p: "--",
          q: "--"
        }, {
          T: "--",
          p: "--",
          q: "--"
        }, {
          T: "--",
          p: "--",
          q: "--"
        }];
      }
    },
    showTitle: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    swap_symbol_id: {
      type: [String, Number]
    },
    marketList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    from_symbol: {
      type: [String, Number],
      default: function _default() {
        return "";
      }
    },
    to_symbol: {
      type: [String, Number],
      default: function _default() {
        return "";
      }
    }
  },
  methods: {
    getValueTime: _getTime.getValueTime
  },
  computed: {
    loading: function loading() {
      var val = 1;

      if (this.orderbookList[0]) {
        if (this.orderbookList[0].T == "--") {
          val = 1;
        } else {
          val = 0;
        }
      } else {
        val = 0;
      }

      return val;
    },
    showList: function showList() {
      return this.orderbookList;
    },
    from_symbol_show: function from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show: function to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    }
  }
};
exports.default = _default2;