"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/maskex-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAffiliateProgram = apiAffiliateProgram;
exports.apiBrokersProgram = apiBrokersProgram;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// boker
function apiBrokersProgram() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Channel/FormSubmit/brokersProgram",
    data: data,
    type: "POST"
  });
} // 联盟计划


function apiAffiliateProgram() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + "/Channel/FormSubmit/affiliateProgram",
    data: data,
    type: "POST"
  });
}